body {
    width: 100vw;
    height: 100vh;
    margin: auto;
    padding: 0;
    font-size: 1rem;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

.react-bootstrap-table table {
    table-layout: auto !important;
    font-size: .8rem !important;
}

.Toastify__toast-container--top-right {
    width: 500px !important;
}

.react-bootstrap-table > table > thead > tr > th .filter {
    font-size: 0.8rem;
}

.react-bootstrap-table > table > thead > tr > th .select-filter.placeholder-selected option:not([value='']) {
    font-size: 0.8rem;
}

.react-time-picker__wrapper {
    border: none !important;
}

.react-bootstrap-table-wrapper {
    overflow-x: auto;
}
